@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
    
  /* width */
  ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
       @apply bg-main-500;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
       @apply bg-gray-400;
      border-radius: 4px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
  .spinStyle{
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #f0f0f085;
    z-index: 9;
  }
  .ant-message-notice-content{
    z-index: 9999;
  }
  .ant-upload-select {
      width: 100%;
    }
  .ant-upload-select button {
      display: flex;
      width: 100%;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  .ant-form-item{
    margin-bottom: 0;
  }
}


