
.HeaderContent {
    background-color: #ffffff; 
    padding: 1rem 2rem; 
  }
  
  .control-btns {
    display: flex;
    align-items: center;
  }
  
  .control-btns .ant-badge {
    margin-right: 1rem; 
  }
  
  .control-btns .text-main-600:hover {
    color: #1d39c4; 
  }
  
  .custom-dropdown {
    margin-left: 1rem; 
  }

.custom-dropdown-button {
    background-color: rgb(0, 90, 114); 
    border: 1px solid #d9d9d9; 
    border-radius: 4px; 
    padding: 8px;
    display: flex;
    align-items: center;
    color: #ffffff; 
    cursor: pointer;
    transition: all 0.3s ease; 
  }
  
  .custom-dropdown-button:hover {
    background-color: rgb(0, 70, 90); 
    border-color: #0056b3; 
  }
  
  .custom-dropdown .ant-dropdown-menu {
    border-radius: 4px; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); 
  }
  
  .custom-dropdown .ant-dropdown-menu-item {
    padding: 10px 16px; 
    transition: background-color 0.3s; 
  }
  
  .custom-dropdown .ant-dropdown-menu-item:hover {
    background-color: rgba(0, 90, 114, 0.1); 
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
}


